import { Button, Col, Row, Typography } from "antd"
import { FC } from "react"
import { Logo } from "src/components/logo"
import './Header.less'
import { Link } from "react-router-dom"
import { Routes } from "src/types/routes"
import { useAuth } from "src/contexts/AuthProvider"

const Header: FC<{ renderMenu?: FC }> = () => {
  const { isAuthenticated } = useAuth()

  return (
    <Row justify={"space-between"}>
      <Col span={24} className={'landing-header'}>
        <div className={'logo-wrapper'}>
          <Logo />

          <Typography.Text className={'logo-title'}>
            Franchise Lead Genie
          </Typography.Text>
        </div>

        <Button className={'features-button body-md-light'}>
          Features
        </Button>

        <Link to={isAuthenticated ? Routes.DASHBOARD : Routes.SIGN_IN} className={'get-started-button'}>
          Get Started
        </Link>
      </Col>
    </Row>
  )
}

export { Header }
