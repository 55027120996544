import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from "react";
import fetchClient from "src/api/fetchClient";
import { getToken, removeToken, setToken } from "src/hooks/useAuthToken";
import { App } from "src/types";

type AuthContextProps = {
  register: (values: { email: string, password: string }) => Promise<any>;
  login: (values: { email: string, password: string }) => Promise<any>;
  confirmEmail: (values: { code: string }) => Promise<any>;
  forgotPassword: (values: { email: string }) => Promise<any>;
  resetPassword: (values: { token: string, confirmPassword: string, password: string }) => Promise<any>
  logout: () => void
  isAuthenticated: boolean;
  authCheckLoading: boolean
  isLoading: boolean;
  user: App.User | null;
  getUser: () => Promise<void>;
}

type AuthContextProviderProps = PropsWithChildren<Partial<AuthContextProps>>;

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}

export const AuthProvider: FC<AuthContextProviderProps> = ({ children, ...props }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [authCheckLoading, setAuthCheckLoading] = useState(true)
  const [user, setUser] = useState(null);

  const login = async (values: { email: string, password: string }): Promise<any> => {
    setIsLoading(true);
    try {
      const res = await fetchClient.post('/auth/login', {
        email: values.email.trim(),
        password: values.password.trim()
      });
      
      if (res.status === 200) {
        setToken(res.data.token);
        setIsAuthenticated(true);
      }

      return res
    } catch (err: any) {
      return err
    } finally {
      setIsLoading(false);
    }
  };
  
  const logout = () => {
    removeToken();
    window.location.replace('/')
  }

  const register = async (values: { email: string, password: string }) => {
    setIsLoading(true);
    try {
      const res =await fetchClient.post('/auth/register', {
        email: values.email.trim(),
        password: values.password.trim()
      })

      return res
    } catch (err: any) {
      return err
    } finally {
      setIsLoading(false);
    }
  }

  const confirmEmail = async (values: { code: string }) => {
    setIsLoading(true);
    const jwt = getToken()

    try {
      let res

      if (jwt) {
        res = await fetchClient.post('/user/profile/update/confirm-email', {
          code: values.code
        })

        if (res.status === 200) {
          removeToken()
        }
      } else {
        res = await fetchClient.post('/auth/register/confirm', {
          code: values.code
        })
      }

      return res
    } catch (err: any) {
      return err
    } finally {
      setIsLoading(false);
    }
  }

  const getUser = async () => {
    setAuthCheckLoading(true)
    try {
      const res = await fetchClient.get('/user/profile')

      if (res.status === 200) {
        setIsAuthenticated(true)
        setUser(res.data)
        setAuthCheckLoading(false)
      }

      if (res.status === 401) {
        removeToken()
        setIsAuthenticated(false)
        setUser(null)
        setAuthCheckLoading(false)
      }
    } catch (err: any) {
      return err
    } finally {
      setAuthCheckLoading(false)
    }
  };

  const forgotPassword = async (values: { email: string }) => {
    
    setIsLoading(true)

    try {
      const res = await fetchClient.post('/auth/forgot-password', {
        email: values.email
      })

      return res
    } catch (err: any) {
      return err
    } finally {
      setIsLoading(false)
    }
  }

  const resetPassword = async (values: { token: string, password: string, confirmPassword: string }) => {
    setIsLoading(true)

    try {
      const res = await fetchClient.post('/auth/reset-password', {
        token: values.token,
        password: values.password.trim(),
        confirmPassword: values.confirmPassword.trim()
      })

      return res
    } catch (err: any) {
      return err
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getUser();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        login,
        register,
        logout,
        user,
        authCheckLoading,
        confirmEmail,
        forgotPassword,
        resetPassword,
        isAuthenticated,
        isLoading,
        getUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
