import { FC, ComponentType, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "src/components/layouts/Loader";
import { useAuth } from "src/contexts/AuthProvider";
import { Routes } from "src/types/routes";

const withAuth = <P extends object>(WrappedComponent: ComponentType<P>): FC<P> => {
  const ComponentWithAuth: FC<P> = (props) => {
    const { isAuthenticated, authCheckLoading } = useAuth();
    
    const navigate = useNavigate();

    useEffect(() => {
      if (!authCheckLoading && !isAuthenticated) {
        navigate(Routes.SIGN_IN, { replace: true });
        
      }
    }, [isAuthenticated, navigate, authCheckLoading]);

    if (authCheckLoading) {
      return (
        <div style={{ 
          width: '100vw', 
          height: '100vh', 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          backgroundColor: '#0F1216'
        }}>
          <Loader spinning size={'large'} />
        </div>
      );
    }


    return <WrappedComponent {...props} />;
  };

  return ComponentWithAuth;
};

export default withAuth;
